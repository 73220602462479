import Cookies from 'js-cookie';
import { useAppSelector } from "../../../services/store";

export const Sidebar = () => {
  const userReducer = useAppSelector(state => state.userReducer);
  return (
    <aside className="sideBarContainer">
      <div className="sideBar">
        <div className="pic"></div>
        <i className="basketSvg"></i>
        <i className="productSvg activSvg"></i>
        <i className="usersSvg activSvg"></i>
        <i className="categorySvg"></i>
        <i className="menuSvg"></i>
        <i className="tagSvg"></i>
        <i className="brandSvg"></i>
        <i className="articleSvg"></i>
        <i className="categorySvg"></i>
        <i className="infoSvg"></i>
        <i className="sidBarExit clickable" onClick={() => {
          Cookies.remove('token');
          window.location.reload();
        }}></i>
      </div>
      <div className="adminInfoContainer">
      <span className="admininfo">
        <i className="adminSvg"></i>
        <div className="adminNameSection">
          <h6 className="adminName">{userReducer.profile?.name}</h6>
          <p className="adminTitle">ادمین</p>
        </div>
      </span>
        <a href="/dashboard/order/" className="sideBarTitle">
        سفارشات
        </a>
        <a href="/dashboard/product/" className="sideBarTitle">
        محصولات
        </a>
        <a href="/dashboard/user/" className="sideBarTitle">
        کاربران
        </a>
        <a href="/dashboard/category/" className="sideBarTitle">
        دسته بندی
        </a>
        <a href="/dashboard/menu/" className="sideBarTitle">
        منوها
        </a>
        <a href="/dashboard/tag/" className="sideBarTitle">
        تگ
        </a>
        <a href="/dashboard/guarantee/" className="sideBarTitle">
        گارانتی
        </a>
        <a href="/dashboard/Brand/" className="sideBarTitle">
        برند
        </a>
      <a href="/dashboard/DashBlog/" className="sideBarTitle">
        مقالات
      </a>
        <a href="/dashboard/banner/" className="sideBarTitle">
        کارت ها
      </a>
        <p className="sideBarTitle">مشخصات فنی</p>
        <a href="/dashboard/attribute/" className="sideBarTitle1">
        لیست مشخصات فنی
        </a>
        <a href="/dashboard/attributegroup/" className="sideBarTitle1">
        دسته بندی مشخصات
        </a>
        <a href="/dashboard/attributeValue" className="sideBarTitle1">
       لیست مقادیر مشخصات فنی
        </a>
      </div>
    </aside>
  );
};