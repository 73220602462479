import moment from 'jalali-moment';
import { ReactElement, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useAppSelector } from '../../services/store';
import tools from '../../utils/tools';

const Factor = () => {
  const { code } = useParams();
  const userReducer = useAppSelector(state => state.userReducer)
  const order = userReducer.orders?.find(e => e.code == code);

  const list = () => {
    const rows: ReactElement[] = [];

    order?.products?.map((orderProduct, index) => rows.push(
      <tr>
        <td colSpan={2}>{tools.formatPrice(orderProduct.count * orderProduct.price)}</td>
        <td colSpan={2}>{tools.formatPrice(orderProduct.price)}</td>
        <td colSpan={1}>{orderProduct.count}</td>
        <td colSpan={2}>{orderProduct.product.sku}</td>
        <td colSpan={4}>{orderProduct.product.title}</td>
        <td colSpan={1}>{++index}</td>
      </tr>
    ))

    return rows
  };

  useEffect(() => {
    window.print()
  }, []);
  return (
    <main className="factorContainer">
      <h1>فاکتور کالا</h1>
      <div className="factorDetails">
        <div className="factorDetailCol marginRightAuto">
          <div className="factorDetailRow end">
            <span>تاریخ ثبت:</span>
            <span>{moment(order?.createdAt).format('jYYYY/jMM/jDD')}</span>
          </div>
          <div className="factorDetailRow end">
            <span>شماره فاکتور:</span>
            <span>{order?.code}</span>
          </div>
        </div>
        <div className="factorDetailCol">
          <div className="factorDetailRow">
            <span>نام و نام خانوادگی:</span>
            <span>{userReducer.profile?.name}</span>
          </div>
        </div>
      </div>
      <table className="factorTable">
        <thead>
          <tr>
            <th colSpan={2}>قیمت کل</th>
            <th colSpan={2}>قیمت واحد</th>
            <th colSpan={1}>تعداد</th>
            <th colSpan={2}>SKU</th>
            <th colSpan={4}>محصولات</th>
            <th colSpan={2}>ردیف</th>
          </tr>
        </thead>
        <tbody>
        {list()}
        <tr>
          <td colSpan={2}>{tools.formatPrice(order.price)}</td>
          <td colSpan={3}>قیمت نهایی</td>
          <td colSpan={7}>{userReducer.address?.province?.title + ' - ' + userReducer.address?.city?.title + ' - ' + userReducer.address?.text }</td>
        </tr>
        </tbody>
      </table>
    </main>
  );
};

export default Factor;
