import { popupSlice } from '../../../services/reducers';
import { Sidebar } from "../Layout/Sidebar";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { setLoading } from "../../../services/reducers/homeSlice";
import restApi from "../../../services/restApi";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import AddCatalog from './AddCatalog';

const AddBrand = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [catalogs, setCatalogs] = useState<any>([]);

  const send = async (e) => {
    e.preventDefault();

    dispatch(setLoading(true));

    const formData = new FormData(e.target);

    catalogs.map((e) => {
      formData.append('catalogFiles', e.file.data)
      formData.append('catalogTitles[]', e.title)
      formData.append('catalogSorts[]', e.sort)
    });

    const res = await restApi(process.env.REACT_APP_BASE_URL + '/admin/brand', true).upload(formData)

    if(res.code == 200){
      Swal.fire({
        title: 'موفق',
        text: 'برند با موفقیت ساخته شد',
        icon: 'success',
        confirmButtonText: 'متوجه شدم',
        didClose() {
          navigate('/dashboard/brand')
        }
      })
    } else {
      Swal.fire({
        title: 'ناموفق',
        text: res?.data,
        icon: 'error',
        confirmButtonText: 'متوجه شدم'
      })
    }
    
    dispatch(setLoading(false));
  }

  return (
    <>
      <main className="dashboardBody">
      <Sidebar />
      <form className="dashBoardMain main" onSubmit={send}>
        <div className="addInfoHeader">
          <button className="dashboardHeader keepRight clickable" type="submit">
            ایجاد برند
          </button>
          <span>
            <h1 className="sideBarTitle">بازگشت به صفحه برند</h1>
             <h1 className="dashBoardTitle">افزودن برند جدید</h1>
          </span>
          <i className="backAdd clickable" onClick={() => navigate("/dashboard/brand/")}></i>
        </div>
        <section className="bottomSection">
          <h1 className="dashBoardTitle">اطلاعات برند</h1>
            <label className="sideBarTitle" htmlFor="infoTitle">عنوان</label>
            <input className="addInput" id="infoTitle" name="title"/>
            <label className="sideBarTitle" htmlFor="infoTitle">کد خارجی</label>
            <input className="addInput" id="infoTitle" name="externalCode"/>
            <label className="sideBarTitle" htmlFor="infoAmount">توضیحات</label>
            <textarea className="addInput" placeholder="توضیحات" id="infoAmount" name="description"/>
        </section>
        <section className="catalogContainer">
        <h1 className="alignSelfEnd">کاتالوگ</h1>
        <table className="catalog">
          <thead>
            <tr>
              <th>ردیف</th>
              <th>عنوان</th>
              <th>عکس</th>
              <th>چینش</th>
              <th className="width100p">
                <span className="dashboardHeader addCatalog clickable" onClick={() => dispatch(popupSlice.middle(<AddCatalog setCatalogs={setCatalogs} />))}>
                  <i className="addPlus"></i>
                  افزودن کاتالوگ جدید
                </span>
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
          {catalogs.map((e, index) =>
            <tr>
              <td>{++index}</td>
              <td>{e.title}</td>
              <td><img className="maxWidth100" src={e.file.preview}/></td>
              <td>{e.sort}</td>
              <td className="around">
                <i className="deleteSvg clickable" onClick={() => {
                  if(confirm('آیا مطمئن هستید؟')) {
                    setCatalogs(catalogs.filter((k, j) => j != index - 1));
                  }
                }}></i>
              </td>
            </tr>
          )}
          </tbody>
        </table>
        </section>
      </form>
      </main>
    </>
  )
}
export default AddBrand;