import ReactPaginate from 'react-paginate';
import usePagination from '../../../hooks/usePagination';
import { Sidebar } from "../Layout/Sidebar";
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../../services/reducers/homeSlice';
import restApi from '../../../services/restApi';

import { useNavigate, useSearchParams } from 'react-router-dom';
import userDashboard from "../../UserDashboard";
import Swal from "sweetalert2";

const UsersList = () => {
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const [query, setQuery] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();
  const { pageCount, endOffset, itemOffset, itemsPerPage, setPageCount, setItemOffset, setItemsPerPage } = usePagination(data)
  let currentItems = data?.slice(itemOffset, endOffset);

  const deleteItem = async (id: number) => {
    if(confirm('آیا مطمئن هستید؟')) {
      dispatch(setLoading(true));
      
      const res = await restApi(process.env.REACT_APP_BASE_URL + '/admin/user/' + id, true).delete({});
      
      if(res.code == 200) {
        Swal.fire({
          title: 'موفق',
          text: 'کاربر با موفقیت حذف شد',
          icon: 'success',
          confirmButtonText: 'متوجه شدم'
        })
      } else {
        Swal.fire({
          title: 'ناموفق',
          text: res?.data,
          icon: 'error',
          confirmButtonText: 'متوجه شدم'
        })
      }
      dispatch(setLoading(false));
    }
  }
  const list = () => {
    const rows = [];
    
    currentItems?.map((user: any, index) => {
      rows.push(
        <tr className="dashTr2">
          <td className="svgContainer">
            <i className="activeUser"></i>
            <i className="trash clickable" onClick={() => deleteItem(user.id)}></i>
            <i className="edit clickable" onClick={() => navigate('/dashboard/user/edit/' + user.id)}></i>
          </td>
          <td className="">{user.lastEntrance}</td>
          <td className="">{user.email}</td>
          <td className="">{user.name}</td>
          <td className="">{user.phoneNumber}</td>
          <td className="">فعال</td>
          <td>{++index}</td>
        </tr>
      )
    })
    
    return rows;
  };
  
  const fetchData = async () => {
    dispatch(setLoading(true));
    
    const res = await restApi(process.env.REACT_APP_BASE_URL + '/admin/user', true).get({
      page: searchParams.get('page') || 1,
      perPage: itemsPerPage,
      query: query
    });

    if(res.code == 200){
      setData(res.data.users);
      setPageCount(Math.floor(res.data.count / itemsPerPage));
    }
    
    dispatch(setLoading(false));
  };
  
  useEffect(() => {
    fetchData();
  }, [searchParams.get('page'), itemsPerPage, query]);

  return(
    
    <>
      <body className="dashboardBody">
      <Sidebar />
      <main className="dashBoardMain">
        <h1 className="dashBoardTitle">لیست کاربران</h1>
        <div className="searchContainer">
          <span className="dashboardHeader clickable" onClick={() => navigate('/dashboard/user/add')}>
            <p>افزودن کاربر جدید</p>
            <i className="addPlus"></i>
          </span>
          <div className='itemsPerPage'>
            <label htmlFor="itemPerPage">تعداد در صفحه</label>
            <input id='itemPerPage' defaultValue={itemsPerPage} onChange={(input) => input.target.value && Number(input.target.value) > 0 && setItemsPerPage(Number(input.target.value))}/>
          </div>
          <div className="dashboardseaechBox">
            <i className="dashMagnifierIcon"></i>
            <input className="dashSearchInput" placeholder="جستجو" onChange={(input: any) => {
              setQuery(input.target.value);
              setSearchParams({['page']: '1'})
            }}></input>
          </div>
        </div>
        <table>
          <thead>
          <tr className="dashTr1">
            <th>عملیات</th>
            <th>آخرین ورود</th>
            <th> ایمیل</th>
            <th>نام و نام خانوادگی</th>
            <th>شماره موبایل</th>
            <th>وضعیت</th>
            <th>ردیف</th>
          </tr>
          </thead>
          {list()}
        </table>
        <ReactPaginate
          breakLabel="..."
          nextLabel="بعدی >"
          onPageChange={(event) => {
            setSearchParams({['page']: (Number(event.selected) + 1).toString()})
            setItemOffset((event.selected * itemsPerPage) % data.length);
          }}
          initialPage={searchParams.get('page') ? Number(searchParams.get('page')) - 1 : 0}
          pageRangeDisplayed={5}
          pageCount={pageCount}
          previousLabel="< قبلی"
          renderOnZeroPageCount={null}
          className="pagination"
          pageClassName="paginationBreak"
          previousClassName="paginationBreak"
          nextClassName="paginationBreak"
          activeClassName="paginationActive"
        />
      </main>
      </body>
    </>
  )
}
export default UsersList;