import { ReactElement } from 'react';
import ProductCard from '../../layouts/ProductCard';
import { useAppSelector } from '../../services/store';
import tools from '../../utils/tools';

const Favorites = () => {
  const favorites = useAppSelector(state => state.userReducer.favorites);

  const list = () => {
    const rows: ReactElement[] = [];

    favorites?.map((favorite: any, index) => {
      rows.push(
        <ProductCard product={favorite.product} key={'fav' + index} />
      );
    });

    return rows;
  };
  return (
    <div className="dashboardTableContainer">
      <div className="favoriteCardContainer">
        {list()}
      </div>
    </div>
  );
};
export default Favorites;