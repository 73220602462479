namespace bannerEnum {
  export enum Banner {
    homeSlider1 = 'اسلایدر اول صفحه اصلی',
    homeSlider2 = 'اسلایدر دوم صفحه اصلی',
    homeSlider3 = 'اسلایدر سوم صفحه اصلی',
    homeCard1 = 'کارت اول صفحه اصلی',
    homeCard2 = 'کارت دوم صفحه اصلی',
    homeCard3 = 'کارت سوم صفحه اصلی',
  }
}
export default bannerEnum;
