import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { popupSlice } from '../../../services/reducers';
import restApi from '../../../services/restApi';

const AddCatalog = ({ setCatalogs }: {
  setCatalogs: React.Dispatch<React.SetStateAction<any[]>>
}) => {
  const [title, setTitle] = useState('');
  const [sort, setSort] = useState('');
  const [file, setFile] = useState({
    preview: '',
    data: '',
  });
  const dispatch: any = useDispatch()

  return (
    <div className="catalogModal">
      <h1>افزودن کاتالوگ</h1>
      <span className="catalogModalRow">
        <label htmlFor="infoTitle">عنوان</label>
        <input className="addInput width300p" id="infoTitle" onChange={(input: any) => setTitle(input.target.value)}/>
      </span>
      <span className="catalogModalRow">
        <label htmlFor="infoTitle">چینش</label>
        <input className="addInput width300p" id="infoTitle" onChange={(input: any) => setSort(input.target.value)}/>
      </span>
      <span className="catalogModalRow">
        <label htmlFor="infoTitle">فایل</label>
        <input className="addInput width300p" type="file" id="infoTitle" onChange={(e: any) => setFile({
          preview: URL.createObjectURL(e.target.files[0]),
          data: e.target.files[0],
        })}/>
      </span>
      <span className="dashboardHeader addCatalog clickable keepRight" onClick={() => {
        setCatalogs((prev) => [...prev, {
          title: title,
          sort: sort,
          file: file
        }])
        dispatch(popupSlice.hide());
      }}>ثبت</span>
    </div>
  );
};

export default AddCatalog;
