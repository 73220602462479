import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { setLoading } from '../../../services/reducers/homeSlice';
import restApi from '../../../services/restApi';
import { Sidebar } from '../Layout/Sidebar';

const AddArticleCategoy = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const send = async (e) => {
    e.preventDefault();
    dispatch(setLoading(true));

    const formData = new FormData(e.target);

    const res = await restApi(process.env.REACT_APP_BASE_URL + '/admin/articleCategory', true).post({
      title: formData.get('title'),
      description: formData.get('description')
    });

    if (res.code == 200) {
      Swal.fire({
        title: 'موفق',
        text: 'دسته بندی  مقاله با موفقیت ساخته شد',
        icon: 'success',
        confirmButtonText: 'متوجه شدم',
        didClose() {
          navigate('/dashboard/articleCategory');
        }
      });
    } else {
      Swal.fire({
        title: 'ناموفق',
        text: res?.data,
        icon: 'error',
        confirmButtonText: 'متوجه شدم'
      });
    }

    dispatch(setLoading(false));
  };

  return (
    <>
      <body className="dashboardBody">
      <Sidebar/>
      <main className="dashBoardMain main">
        <form onSubmit={send}>
          <div className="addInfoHeader">
            <button className="dashboardHeader keepRight clickable" type="submit">
              ایجاد دسته بندی مقاله
            </button>
            <span>
              <h1 className="sideBarTitle">بازگشت به صفحه دسته بندی  مقالات</h1>
               <h1 className="dashBoardTitle">افزودن دسته بندی  مقاله جدید</h1>
            </span>
            <i className="backAdd clickable" onClick={() => navigate('/dashboard/articleCategory/')}></i>
          </div>
          <section className="bottomSection">
            <h1 className="dashBoardTitle">اطلاعات دسته بندی مقاله</h1>
            <label className="sideBarTitle" htmlFor="infoTitle">عنوان</label>
            <input className="addInput" id="infoTitle" name="title"/>
            <label className="sideBarTitle" htmlFor="infoAmount">توضیح</label>
            <textarea className="addInput" placeholder="توضیحات" name="description" id="infoAmount"/>
          </section>
        </form>
      </main>
      </body>
    </>
  );
};
export default AddArticleCategoy;