import { ReactElement, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import BannerEnum from '../../../../enums/bannerEnum';
import { setLoading } from '../../../../services/reducers/homeSlice';
import restApi from '../../../../services/restApi';
import tools from '../../../../utils/tools';
import { Sidebar } from '../../Layout/Sidebar';

const BannerManage = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form, setForm] = useState<any>();
  const [image, setImage] = useState<any>({});
  const { id: paramId } = useParams();

  const submit = async () => {
    dispatch(setLoading(true));
    const res = await restApi(process.env.REACT_APP_BASE_URL + '/admin/banner/basic/' + (paramId || ''), true).post({
      code: form.code,
      title: form.title,
      alt: form.alt,
      link: form.link,
      description: form.description,
    });

    if (image.data) {
      const formData = new FormData();

      formData.append('file', image.data);

      const mediaRes = await restApi(process.env.REACT_APP_BASE_URL + '/admin/banner/medias/' + res.data.id, true).upload(formData);
    }

    if (res.code == 200) {
      Swal.fire({
        title: 'موفق',
        text: `کارت با موفقیت ${paramId ? 'ویرایش' : 'اضافه'} شد`,
        icon: 'success',
        confirmButtonText: 'متوجه شدم',
        didClose() {
          // navigate('/dashboard/banner/')
        }
      });
    } else {
      Swal.fire({
        title: 'ناموفق',
        text: res?.data,
        icon: 'error',
        confirmButtonText: 'متوجه شدم'
      });
    }

    dispatch(setLoading(false));
  };

  const fetchData = async () => {
    dispatch(setLoading(true));

    const res = await Promise.all([
      paramId && restApi(process.env.REACT_APP_BASE_URL + '/admin/banner/single/' + (paramId), true ).get(),
    ]);

    if (paramId) {
      setForm({
        title: res[0].data?.title,
        code: res[0].data?.code,
        link: res[0].data?.link,
        alt: res[0].data?.alt,
        description: res[0].data?.description,
      })
      if (res[0].data?.media?.url){
        setImage({ preview: res[0].data?.media?.url, data: undefined})
      }
    }

    dispatch(setLoading(false));
  };

  useEffect(() => {
    fetchData();
  }, []);


  return (
    <>
      <main className="dashboardBody">
        <Sidebar/>
        <div className="dashBoardMain main">
          <div className="addInfoHeader">
            <div className="buttonContainer keepRight">
              <button className="dashboardHeader keepRight" onClick={() => submit()}>
                <p>{paramId ? 'ویرایش' : 'ایجاد'} کارت</p>
              </button>
            </div>
            <span>
          <h1 className="sideBarTitle">بازگشت به صفحه کارت ها</h1>
           <h1 className="dashBoardTitle">{paramId ? 'ویرایش' : 'ایجاد'} کارت</h1>
        </span>
            <i className="backAdd" onClick={() => navigate('/dashboard/banner')}></i>
          </div>
          <section className='addInfoSec'>
            <div className="AddInfoContainer">
              <label>عنوان</label>
              <input className="persianName" defaultValue={form?.title} onChange={(input) => setForm((prev) => ({...prev, title: input.target.value}))}/>
              <label>لینک</label>
              <input className="persianName" defaultValue={form?.link} onChange={(input) => setForm((prev) => ({...prev, link: input.target.value}))}/>
              <label>متن جایگزین</label>
              <input className="persianName" defaultValue={form?.alt} onChange={(input) => setForm((prev) => ({...prev, alt: input.target.value}))}/>
              <label>توضیحات</label>
              <input className="persianName" defaultValue={form?.description} onChange={(input) => setForm((prev) => ({...prev, description: input.target.value}))}/>
              <label>جایگاه</label>
              <select className="persianName" value={form?.code} onChange={(input) => setForm((prev) => ({...prev, code: input.target.value}))}>
                <option value={undefined}>انتخاب کنید</option>
                {Object.entries(BannerEnum.Banner).map(([key, value], index) => <option value={key}>{value}</option>)}
              </select>
            </div>
            <div className="AddInfoContainer">
              <input type="file" onChange={(input) => setImage({
                data: input.target.files[0],
                preview: URL.createObjectURL(input.target.files[0]),
              })}/>
              <img src={image.preview} alt='عکس' className="bannerImage"/>
            </div>
          </section>
        </div>
      </main>
    </>
  );
};
export default BannerManage;