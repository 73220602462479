import { ReactElement, useState } from 'react';

const MenuModal = ({ activeImage, setActiveImage, menu, setMenu }: any) => {
  const [childMenu, setChildMenu] = useState<any>();

  const parentList = () => {
    const rows: ReactElement[] = [];

    menu.children.map((child, index) => rows.push(
        <a key={'parentMenu' + index} href={child.link} onMouseEnter={() => setChildMenu(child)}>{child.title}</a>
    ))

    return rows;
  };

  const list = () => {
    const rows: ReactElement[] = [];

    childMenu?.children?.map((child, index) => rows.push(
      <>
        <a href={child.link}><h5>{child.title}</h5></a>
        {child.children.map((grandChild, index) => <a href={grandChild.link}>{grandChild.title}</a>)}
      </>
    ))

    return rows;
  };

  return (
    <div className={'menuBox flex'}  onMouseLeave={() => setMenu()}>
      <img src='/img/Menu-Image.jpg'/>
      <div className="links">
        {list()}
      </div>
      <div className="rightLinks">
        {parentList()}
      </div>
    </div>
  );
};

export default MenuModal;
