import { Sidebar } from "../Layout/Sidebar";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setLoading } from "../../../services/reducers/homeSlice";
import restApi from "../../../services/restApi";
import Swal from "sweetalert2";
import { useNavigate, useParams } from "react-router-dom";

const EditAttributeGroup = () => {
  const [item, setItem] = useState<any>()
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const dispatch = useDispatch();
  const { id } = useParams();
  console.log(id)
  const navigate= useNavigate()
  const send = async () => {
    dispatch(setLoading(true));
    
    const res = await restApi(process.env.REACT_APP_BASE_URL + '/admin/attributegroup/' + id, true).put({
      title: title,
      description: description
    })
    
    if(res.code == 200){
      Swal.fire({
        title: 'موفق',
        text: 'دسته بندی مشخصات با موفقیت ویرایش شد',
        icon: 'success',
        confirmButtonText: 'متوجه شدم'
      })
    } else {
      Swal.fire({
        title: 'ناموفق',
        text: res?.data,
        icon: 'error',
        confirmButtonText: 'متوجه شدم'
      })
    }
    
    dispatch(setLoading(false));
  }
  
  const fetchItem = async () => {
    dispatch(setLoading(true))
    
    const res = await restApi(process.env.REACT_APP_BASE_URL + '/admin/attributegroup/' + id, true).get()
    
    if(res.code == 200) {
      setItem(res.data)
    }
    
    dispatch(setLoading(false))
  }
  
  useEffect(() => {
    fetchItem()
  }, [])
  return (
    <>
      <body className="dashboardBody">
      <Sidebar />
      <main className="dashBoardMain main">
        <div className="addInfoHeader">
          <span className="dashboardHeader keepRight clickable" onClick={send}>
           ارسال
          </span>
          <span>
            <h1 className="sideBarTitle">بازگشت به صفحه لیست دسته بندی مشخصات</h1>
             <h1 className="dashBoardTitle">ویرایش دسته بندی  مشخصات </h1>
          </span>
          <i className="backAdd clickable" onClick={() => navigate("/dashboard/attributegroup/")}></i>
        
        </div>
        <section className="bottomSection">
          <h1 className="dashBoardTitle">اطلاعات دسته بندی مشخصات</h1>
          <form className="addInfo">
            <label className="sideBarTitle" htmlFor="infoTitle">عنوان</label>
            <input className="addInput" id="infoTitle" onChange={(input: any) => setTitle(input.target.value)} value={title || item?.title}/>
            <label className="sideBarTitle" htmlFor="infoAmount">توضیحات</label>
            <textarea className="addInput" placeholder="توضیحات" id="infoAmount" onChange={(input: any) => setDescription(input.target.value)} value={description || item?.description}/>
          </form>
        </section>
      </main>
      </body>
    </>
  )
}
export default EditAttributeGroup;