import React, { useState } from 'react';

const InfoRow = ({ title, description }) => {
  const [isActive, setIsActive] = useState(false);
  return (
    <div className="discriptionSection" onClick={() => setIsActive(!isActive)}>
                <span className="discriptionTitle">
                  <h1 className="discriptionText">{title}</h1>
                  <i className={isActive ? 'collapsIcon' : 'plusIcon'}></i>
                </span>
      { isActive && (description as []).map((e) =>
        <p className="description">
          {e}
        </p>
      )}
    </div>
  );
};

export default InfoRow;
