import { Sidebar } from "../Layout/Sidebar";
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../../services/reducers/homeSlice';
import restApi from '../../../services/restApi';

import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

const DashBlog = () => {
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [query, setQuery] = useState('');
  const deleteItem = async (id: number) => {
    if(confirm('آیا مطمئن هستید؟')){
      dispatch(setLoading(true));
      
      const res = await restApi(process.env.REACT_APP_BASE_URL + '/admin/article/' + id, true).delete({});
      
      if(res.code == 200){
        Swal.fire({
          title: 'موفق',
          text: 'مقاله با موفقیت حذف شد',
          icon: 'success',
          confirmButtonText: 'متوجه شدم'
        })
      } else {
        Swal.fire({
          title: 'ناموفق',
          text: res?.data,
          icon: 'error',
          confirmButtonText: 'متوجه شدم'
        })
      }
      
      dispatch(setLoading(false));
    }
  }
  
  const list = () => {
    const rows = [];
    
    data.filter((dashblog:any) => dashblog.title.toLowerCase().includes(query.toLowerCase())).map((dashblog: any, index) => {
      rows.push(
        <tr className="">
          <td className="svgContainer">
            <i className="trash clickable" onClick={() => deleteItem(dashblog.id)}></i>
            <i className="edit clickable" onClick={() => navigate('/dashboard/dashblog/edit/' + dashblog.id)}></i>
          </td>
          <td className="">{dashblog.createdAt}</td>
          <td className="">{dashblog.long.toString().substring(0, 20)} ...</td>
          <td className="">{dashblog.short}</td>
          <td className="">{dashblog.keywords}</td>
          <td className="">{dashblog.title}</td>
          <td>{++index}</td>
        </tr>
      )
    })
    
    return rows;
  };
  
  const fetchData = async () => {
    dispatch(setLoading(true));
    
    const res = await restApi(process.env.REACT_APP_BASE_URL + '/admin/article', true).get();
    console.log(res)
    if(res.code == 200){
      setData(res.data);
    }
    
    dispatch(setLoading(false));
  };
  
  useEffect(() => {
    fetchData();
  }, []);

  return(
    
    <>
      <body className="dashboardBody">
      <Sidebar />
      <main className="dashBoardMain">
        <h1 className="dashBoardTitle">لیست  مقالات</h1>
        <div className="searchContainer">
          <div className="keepRight svgContainer">
          <span className="dashboardHeader clickable" onClick={() => navigate('/dashboard/dashblog/add')}>
            <p>افزودن مقاله جدید</p>
            <i className="addPlus"></i>
          </span>
          <span className="dashboardHeader clickable" onClick={() => navigate('/dashboard/articleCategory/')}>
            <p> دسته بندی مقالات</p>
          </span>
          </div>
          <div className="dashboardseaechBox">
            <i className="dashMagnifierIcon"></i>
            <input className="dashSearchInput" placeholder="جستجو" onChange={(input: any) => setQuery(input.target.value)}></input>
          </div>
        </div>
        <table className="listTable">
          <thead>
            <tr className="firstTR">
              <th>عملیات</th>
              <th>تاریخ ساخت</th>
              <th>توضیح بلند</th>
              <th>توضیح کوتاه</th>
              <th>کلید واژه</th>
              <th>عنوان</th>
              <th>ردیف</th>
            </tr>
          </thead>
          <tbody>
          {list()}
          </tbody>
        </table>
      </main>
      </body>
    </>
  )
}
export default DashBlog;