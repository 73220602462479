import { ReactElement, useState } from 'react';
import Swal from 'sweetalert2';
import { popupSlice } from "../../../services/reducers";
import { useDispatch } from "react-redux";
import { setLoading } from '../../../services/reducers/homeSlice';
import restApi from '../../../services/restApi';
import product from '../Product';

const SyncModal = ({ products, id }) =>{
  const [selected, setSelected] = useState({});
  const dispatch: any = useDispatch();

  const submit = async () => {
    dispatch(setLoading(true));

    const res = await restApi(process.env.REACT_APP_BASE_URL + '/admin/brand/sync/' + id, true).post({ products: selected});

    if(res.code == 200) {
      Swal.fire({
        title: 'موفق',
        text: 'محصولات با موفقیت بروزرسانی شدند',
        icon: 'success',
        confirmButtonText: 'متوجه شدم'
      })
    } else {
      Swal.fire({
        title: 'ناموفق',
        text: res?.data,
        icon: 'error',
        confirmButtonText: 'متوجه شدم'
      })
    }

    dispatch(setLoading(false));
  };
  const list = () => {
    const rows: ReactElement[] = [];

    Object.entries(products).map(([key, value]: [key: string, value: any], index)=> rows.push(
      <>
      <tr className={index % 2 == 0 ? 'backGrey' : ''}>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
        <td>{key}</td>
        <td><input type="checkbox"  onChange={(input) => setSelected(prev => {
          const cp = { ...prev };

          if (!Array.isArray(cp[key])){
            cp[key] = [];
          }

          if (!input.target.checked && Object.values(selected).reduce<any[]>((acc: any, curr:any) => [...acc, ...curr], []).filter(e => value.map(e => e.id).includes(e)).length > 0){
            delete cp[key];
          }else{
            cp[key] = value.map(e => e.id)
          }
          return cp
        })}/></td>
      </tr>
        {value.map((product, i) => {
          // console.log(Object.values(selected).filter((e: any) => e.id == product.id));
          const split = product.name.split('-');
          return(
            <tr key={key + i} className={index % 2 == 0 ? 'backGrey' : ''}>
              <td>{split[3]}</td>
              <td>{split[2]}</td>
              <td>{split[0]}</td>
              <td><img className="syncBrandImage" src={product.thumbnail_url} alt={product.name}/></td>
              <td><input type="checkbox" checked={Object.values(selected).reduce<any[]>((acc: any, curr:any) => [...acc, ...curr], []).filter(e => e == product.id).length > 0}  onClick={() => setSelected(prev => {
                const cp = {...prev};

                if (!Array.isArray(cp[key])){
                  cp[key] = []
                }

                if(Object.values(selected).reduce<any[]>((acc: any, curr:any) => [...acc, ...curr], []).filter(e => e == product.id).length > 0){
                  cp[key] = cp[key].filter(e => e != product.id)
                  if (cp[key].length == 0) {
                    delete cp[key];
                  }
                } else {
                  cp[key].push(product.id);
                }

                return cp
              })}/></td>
              <td></td>
            </tr>
          );
        })}
      </>
    ));

    return rows;
  };
  return(
    <main className="syncBrand">
      <span className="selectAll">
        <input type="checkbox" checked={Object.values(selected).reduce<any[]>((acc: any, curr:any) => [...acc, ...curr], []).length == Object.values(products).reduce<any[]>((acc: any, curr:any) => [...acc, ...curr], []).length} onClick={(input: any) => {
          const cp: any = {};

           if (Object.values(selected).reduce<any[]>((acc: any, curr:any) => [...acc, ...curr], []).length != Object.values(products).reduce<any[]>((acc: any, curr:any) => [...acc, ...curr], []).length) {
             Object.entries(products).flatMap(([key, value]: [key: string, value: any]) => cp[key] = value.map(e => e.id))
           }
          setSelected(prev => cp)
        }}/>
        <label htmlFor="selectAll" >({Object.values(products).reduce<any[]>((acc: any, curr:any) => [...acc, ...curr], []).length}انتخاب همه)</label>
      </span>
      <table className="syncBrandTable">
        <thead>
          <tr>
            <th>مدل</th>
            <th>رنگ</th>
            <th>sku</th>
            <th>عکس</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
        {list()}
        </tbody>
      </table>
      <span className="syncBrandButtons">
        <button onClick={submit}>ثبت</button>
        <button onClick={() => dispatch(popupSlice.hide())}>لغو</button>
      </span>
    </main>
  )
}
export default SyncModal