import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { setLoading } from '../../../services/reducers/homeSlice';
import restApi from '../../../services/restApi';
import { Sidebar } from '../Layout/Sidebar';

const AddDashBlog = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedAttributeGroups, setSelectedAttributeGroups] = useState([]);
  const [articleCategories, setArticleCategories] = useState([]);
  const [file, setFile] = useState({
    preview: '',
    data: '',
  });
  const send = async (e) => {
    e.preventDefault();
    dispatch(setLoading(true));

    const formData = new FormData(e.target);

    formData.append('file', file.data);
    selectedAttributeGroups.map((e) => formData.append('articleCategories[]', e));

    const res = await restApi(process.env.REACT_APP_BASE_URL + '/admin/article', true).upload(formData);

    if (res.code == 200) {
      Swal.fire({
        title: 'موفق',
        text: 'مقاله با موفقیت ساخته شد',
        icon: 'success',
        confirmButtonText: 'متوجه شدم',
        didClose() {
          navigate('/dashboard/dashBlog/')
        }
      });
    } else {
      Swal.fire({
        title: 'ناموفق',
        text: res?.data,
        icon: 'error',
        confirmButtonText: 'متوجه شدم'
      });
    }

    dispatch(setLoading(false));
  };
  const fetchData = async () => {
    dispatch(setLoading(true));

    const res = await restApi(process.env.REACT_APP_BASE_URL + '/admin/articleCategory', true).get();

    if (res.code) {
      setArticleCategories(res.data);
    }

    dispatch(setLoading(false));
  };
  useEffect(() => {
    fetchData();
  }, []);
  console.log(selectedAttributeGroups);
  return (
    <>
      <body className="dashboardBody">
      <Sidebar/>
      <main className="dashBoardMain main">
        <form onSubmit={send}>
          <div className="addInfoHeader">
            <button className="dashboardHeader keepRight clickable" type="submit">
              ایجاد مقاله
            </button>
            <span>
              <h1 className="sideBarTitle">بازگشت به صفحه مقالات</h1>
               <h1 className="dashBoardTitle">افزودن مقاله جدید</h1>
            </span>
            <i className="backAdd clickable" onClick={() => navigate('/dashboard/dashblog/')}></i>
          </div>
          <section className="bottomSection">
            <h1 className="dashBoardTitle">اطلاعات مقاله</h1>
            <label className="sideBarTitle" htmlFor="infoTitle">عنوان</label>
            <input className="addInput" id="infoTitle" name="title"/>
            <label className="sideBarTitle" htmlFor="infoTitle">دسته بندی</label>
            <select className="addInput dashboardSelector" id="infoTitle" name="attributeGroups" multiple={true}
                    onChange={(input: any) => setSelectedAttributeGroups(Array.from(input.target.selectedOptions, (option: any) => option?.value))}>
              {articleCategories?.map((attributeGroup: any) => <option value={attributeGroup.id}>{attributeGroup.title}</option>)}
            </select>
            <label className="sideBarTitle" htmlFor="infoTitle">کلیدواژه</label>
            <input className="addInput" id="infoTitle" name="keywords"/>
            <label className="sideBarTitle" htmlFor="infoAmount">توضیح کوتاه</label>
            <textarea className="addInput" placeholder="توضیحات" name="short" id="infoAmount"/>
            <label className="sideBarTitle" htmlFor="infoAmount">توضیح بلند</label>
            <textarea className="addInput" placeholder="توضیحات" name="long" id="infoAmount"/>
            <input type="file" onChange={(e: any) => setFile({
              preview: URL.createObjectURL(e.target.files[0]),
              data: e.target.files[0],
            })}/>
            <img src={file.preview}/>
          </section>
        </form>
      </main>
      </body>
    </>
  );
};
export default AddDashBlog;