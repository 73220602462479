import moment from 'jalali-moment';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { popupSlice } from '../../../services/reducers';
import { setLoading } from '../../../services/reducers/homeSlice';
import restApi from '../../../services/restApi';
import { Sidebar } from '../Layout/Sidebar';
import Derham from '../Modal/Derham';

const PreProducts = () => {
  const [data, setData] = useState([]);
  const [query, setQuery] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const deleteItem = async (id: number) => {
    if(confirm('آیا مطمئن هستید؟')){
      dispatch(setLoading(true));

      const res = await restApi(process.env.REACT_APP_BASE_URL + '/admin/product/' + id, true).delete({});

      if(res.code == 204){
        Swal.fire({
          title: 'موفق',
          text: 'محصول با موفقیت حذف شد',
          icon: 'success',
          confirmButtonText: 'متوجه شدم'
        })
      } else {
        Swal.fire({
          title: 'ناموفق',
          text: res?.data,
          icon: 'error',
          confirmButtonText: 'متوجه شدم'
        })
      }

      dispatch(setLoading(false));
    }
  }

    const deleteAll = async () => {
    if(confirm('آیا مطمئن هستید؟')){
      dispatch(setLoading(true));

      const res = await restApi(process.env.REACT_APP_BASE_URL + '/admin/product/pre/deleteAll', true).delete({});

      if(res.code == 200){
        Swal.fire({
          title: 'موفق',
          text: 'محصول با موفقیت حذف شد',
          icon: 'success',
          confirmButtonText: 'متوجه شدم'
        })
      } else {
        Swal.fire({
          title: 'ناموفق',
          text: res?.data,
          icon: 'error',
          confirmButtonText: 'متوجه شدم'
        })
      }

      dispatch(setLoading(false));
    }
  }

  const list = () => {
    const rows = [];

    data?.filter((product) => product.sku.includes(query)).map((product: any, index) => {
      rows.push(
        <tr className="dashTr2">
          <td className="svgContainer">
            <i className="trash clickable" onClick={() => deleteItem(product.id)}></i>
            <i className="edit clickable" onClick={() => navigate('/dashboard/product/edit/' + product.id)}></i>
          </td>
          <td>{product.price}</td>
          <td>{product.count}</td>
          <td className="dateTd">
            <p>{moment(product?.createdAt).format('jYYYY/jMM/jDD')}</p>
            <p>{moment(product?.createdAt).format('HH:SS')}</p>
          </td>
          <td className="productCode">{product?.sku}</td>
          <td>{++index}</td>
        </tr>
      )
    })

    return rows;
  };

  const fetchData = async () => {
    dispatch(setLoading(true));

    const res = await restApi(process.env.REACT_APP_BASE_URL + '/admin/product/pre/list', true).get();

    if(res.code == 200){
      setData(res.data);
    }

    dispatch(setLoading(false));
  };

  useEffect(() => {
    fetchData();
  }, []);

  return(
    <>
      <body className="dashboardBody">
      <Sidebar />
      <main className="dashBoardMain">
        <h1 className="dashBoardTitle">لیست پیش محصولات</h1>
        <div className="searchContainer">
          <div className="keepRight svgContainer">
            <span className="dashboardHeader clickable" onClick={() => navigate('/dashboard/product')}>
              <p>محصولات</p>
            </span>
            <span className="dashboardHeader clickable" onClick={() => deleteAll()}>
              <p>حذف همه</p>
            </span>
          </div>
          <div className="dashboardseaechBox">
            <i className="dashMagnifierIcon"></i>
            <input className="dashSearchInput" placeholder="جستجو" onChange={(input) => setQuery(input.target.value)}/>
          </div>
        </div>
      <table>
        <tr className="dashTr1">
          <td>عملیات</td>
          <td>قیمت</td>
          <td>تعداد</td>
          <td>تاریخ ساخت</td>
          <td className="productCodeTitle">کد کالا</td>
          <td>ردیف</td>
        </tr>
        {list()}
      </table>
      </main>
      </body>
    </>
  )
}
export default PreProducts