const LoadingBody = () => {
  return (
    <div className="loading-wrapper">
      <div className="loading-spinner">
        <div className="loading">
          <div></div>
        </div>
      </div>
    </div>
  );
};

export default LoadingBody;
