import { Sidebar } from "../Layout/Sidebar";

import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setLoading } from "../../../services/reducers/homeSlice";
import restApi from "../../../services/restApi";
import Swal from "sweetalert2";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const EditMenu = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const [item, setItem] = useState<any>()
  const [title, setTitle] = useState('');
  const [link, setLink] = useState('');
  const [description, setDescription] = useState('');
  const [menus,setMenus]=useState([])
  const [parentId, setParentId] = useState()

  const send = async () => {
    dispatch(setLoading(true));
    
    const res = await restApi(process.env.REACT_APP_BASE_URL + '/admin/menu/' + id, true).put({
      title: title,
      description: description,
      parentId: parentId,
      link: link,
    })
    
    if(res.code == 200){
      Swal.fire({
        title: 'موفق',
        text: 'منو با موفقیت ویرایش شد',
        icon: 'success',
        confirmButtonText: 'متوجه شدم'
      })
    } else {
      Swal.fire({
        title: 'ناموفق',
        text: res?.data,
        icon: 'error',
        confirmButtonText: 'متوجه شدم'
      })
    }
    
    dispatch(setLoading(false));
  }
  
  const fetchItem = async () => {
    dispatch(setLoading(true))
    
    const res = await restApi(process.env.REACT_APP_BASE_URL + '/admin/menu/' + id, true).get()
    
    if(res.code == 200) {
      setItem(res.data)
    }
    const result = await restApi(process.env.REACT_APP_BASE_URL + '/admin/menu/', true).get()
    if(result.code== 200){
      setMenus(result.data)
    }
    dispatch(setLoading(false))
  }
  
  useEffect(() => {
    fetchItem()
  }, [])
  console.log(parentId)
  return (
    <>
      <body className="dashboardBody">
      <Sidebar />
      <main className="dashBoardMain main">
        <div className="addInfoHeader">
          <span className="dashboardHeader keepRight clickable" onClick={send}>
           ارسال
          </span>
          <span>
            <h1 className="sideBarTitle">بازگشت به صفحه منو ها</h1>
             <h1 className="dashBoardTitle">ویرایش منو </h1>
          </span>
          <i className="backAdd clickable" onClick={() => navigate("/dashboard/menu/")}></i>
        </div>
        <section className="bottomSection">
          <h1 className="dashBoardTitle">اطلاعات منو</h1>
          <form className="addInfo">
            <label className="sideBarTitle" htmlFor="infoTitle">عنوان</label>
            <input className="addInput" id="infoTitle" onChange={(input: any) => setTitle(input.target.value)} value={title || item?.title}/>
            <label className="sideBarTitle" htmlFor="infoTitle">لینک</label>
            <input className="addInput" id="infoTitle" onChange={(input: any) => setLink(input.target.value)} value={link || item?.link}/>
            <label className="sideBarTitle" >والد</label>
            <select className="selector" id="infoTitle" onChange={(input: any) => setParentId(input.target.value)}>
              <option value={0}>بدون والد</option>
              {menus?.map((menu: any) =>  <option selected={menu.id == item.parentId} value={menu.id}>{menu.title}</option>)}
            </select>
            <label className="sideBarTitle" htmlFor="infoAmount">توضیحات</label>
            <textarea className="addInput" placeholder="توضیحات" id="infoAmount" onChange={(input: any) => setDescription(input.target.value)} value={description || item?.description}/>
          </form>
        </section>
      </main>
      </body>
    </>
  )
}
export default EditMenu;