import { Sidebar } from "../Layout/Sidebar";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setLoading } from "../../../services/reducers/homeSlice";
import restApi from "../../../services/restApi";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

const AddCategory = () => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const [categories,setCategories]=useState([])
  const [parentId, setParentId] = useState()
  
  const send = async () => {
    dispatch(setLoading(true));
    
    const res = await restApi(process.env.REACT_APP_BASE_URL + '/admin/category', true).post({
      title: title,
      description: description,
      parentId: parentId
    })
    
    if(res.code == 200){
      Swal.fire({
        title: 'موفق',
        text: 'دسته بندی با موفقیت ساخته شد',
        icon: 'success',
        confirmButtonText: 'متوجه شدم',
        didClose() {
          navigate('/dashboard/category')
        }
      })
    } else {
      Swal.fire({
        title: 'ناموفق',
        text: res?.data,
        icon: 'error',
        confirmButtonText: 'متوجه شدم'
      })
    }
    
    dispatch(setLoading(false));
  }
  const fetchData = async () => {
    dispatch(setLoading(true));
    
    const res = await restApi(process.env.REACT_APP_BASE_URL + '/admin/category', true).get();
    console.log(res)
    if(res.code == 200){
      setCategories(res.data);
    }
    
    dispatch(setLoading(false));
  };
  useEffect(() => {
    fetchData()
  }, [])
  return (
    <>
      <body className="dashboardBody">
      <Sidebar />
      <main className="dashBoardMain main">
        <div className="addInfoHeader">
          <span className="dashboardHeader keepRight clickable" onClick={send}>
            ایجاد دسته بندی
          </span>
          <span>
            <h1 className="sideBarTitle">بازگشت به صفحه دسته بندی ها</h1>
             <h1 className="dashBoardTitle">افزودن دسته بندی جدید</h1>
          </span>
          <i className="backAdd clickable" onClick={() => navigate("/dashboard/category/")}></i>
        </div>
        <section className="bottomSection">
          <h1 className="dashBoardTitle">اطلاعات دسته بندی</h1>
          <form className="addInfo">
            <label className="sideBarTitle" htmlFor="infoTitle">عنوان</label>
            <input className="addInput" id="infoTitle" onChange={(input: any) => setTitle(input.target.value)}/>
            <label className="sideBarTitle" >والد</label>
            <select className="selector" id="infoTitle" onChange={(input: any) => setParentId(input.target.value)}>
              <option value={null}>بدون والد</option>
              {categories?.map((category: any) =>  <option value={category.id}>{category.title}</option>)}
            </select>
            <label className="sideBarTitle" htmlFor="infoAmount">توضیحات</label>
            <textarea className="addInput" placeholder="توضیحات" id="infoAmount" onChange={(input: any) => setDescription(input.target.value)}/>
          </form>
        </section>
      </main>
      </body>
    </>
  )
}
export default AddCategory;