import Cookies from 'js-cookie';
import { useDispatch } from 'react-redux';
import { popupSlice } from '../../services/reducers';
import { useAppSelector } from '../../services/store';
import AddAddress from './AddAddress';
import EditAddress from './EditAddress';

const SideBar = () => {
  const dispatch = useDispatch()
  const userReducer = useAppSelector(state => state.userReducer);

  return (
    <aside className="catRightSection">
      <span>مشخصات کاربری</span>
      <span className="catInfoName">{userReducer.profile?.name}</span>
      <div className="catInfo">
            <span className="catSpanInfo">
              <i className=""></i>
              <div className="catText">
                <span className="cardsubtitle">ایمیل</span>
                <span>{userReducer.profile?.email}</span>
              </div>
            </span>
        <span className="catSpanInfo">
              <i className="" ></i>
              <div className="catText">
                <span className="cardsubtitle">شماره همراه</span>
                <span>{userReducer.profile?.phoneNumber}</span>
              </div>
            </span>
      </div>
      <span className="cardsubtitle">{userReducer.address?.title}</span>
      <p className="catAdresses">{userReducer.address?.text}<br/>{userReducer.address?.phoneNumber}</p>
      <div>
        {userReducer.address ?
          <button className="editAdressButton clickable" onClick={()=>dispatch(popupSlice.middle(<EditAddress address={userReducer.address}/>))}>
            <p>ویرایش آدرس </p>
            <i className="adressEditSvg"></i>
          </button>
          :
          <span className="newAdressButton clickable" onClick={() => dispatch((popupSlice.middle(<AddAddress />)))}>
            <p>ثبت آدرس</p>
            <i className="adressPlusSvg"></i>
          </span>
          }
      </div>
      <span className="logout clickable" onClick={() => {
        Cookies.remove('token');
        window.location.reload();
      }}>
        خروج
        <i className="logoutSvg"></i>
      </span>
    </aside>
  );
};

export default SideBar;
