import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { popupSlice } from '../../services/reducers';

const Gallery = ({ images } ) => {
  const [selected, setSelected] = useState(images[0]?.url);
  const dispatch: any = useDispatch();

  return (
    <section className="galleryContainer">
      <span>
      <p className="titles">گالری</p>
      <i className="modalExit clickable" onClick={() => dispatch(popupSlice.hide())}></i>
    </span>
      <img className="galleryMainImage" src={selected}/>
      <div className="galleryModalImages">
        {images.map(e => <img className={`galleryImage ${selected == e.url ? 'selected' : ''}`} src={e.url} alt={e.title} onClick={() => setSelected(e.url)}/>)}
      </div>
    </section>
  );
};

export default Gallery;
