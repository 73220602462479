import { useNavigate } from 'react-router-dom';
import Header from '../../layouts/Header';

const Success = () =>{
  const navigate = useNavigate();
  return(
    <>
     <Header/>
      <section className="successPage">
        <i className="successSvg"></i>
        <p className="successText">سفارش شما با موفقیت ثبت شد</p>
        <p className="successText2">در صورت تایید سفارش از طریق داشبورد خود برای پرداخت اقدام فرمایید</p>
        <span className="successBackButton clickable" onClick={() => window.location.href = '/profile'}>
          <i className="backSvg"></i>
          <p>پیگیری سفارش</p>
        </span>
      </section>
    </>
  )
}
export default Success


