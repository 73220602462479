import 'swiper/css';
import 'swiper/css/navigation';
import Cookies from 'js-cookie';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import endpoints from '../../config/endpoints';
import cookieEnum from '../../enums/cookieEnum';
import { setLoading } from '../../services/reducers/homeSlice';
import restApi from '../../services/restApi';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const send = async () => {
    dispatch(setLoading(true));

    const res = await restApi(process.env.REACT_APP_BASE_URL + endpoints.login).post({
      phoneNumber: username,
      password: password
    });

    dispatch(setLoading(false));

    if (res.code == 200){
      Cookies.set('token', res.data.token);
      if (res.data.role === "SUPER_ADMIN"){
        navigate('/dashboard/product/');
      } else {
        navigate('/');
      }
    }else if(res.code == 401){
      Swal.fire({
        title: 'ناموفق',
        text: 'نام کاربری یا رمز عبور صحیح نمی باشد',
        icon: 'error',
        confirmButtonText: 'متوجه شدم'
      });
    }else{
      Swal.fire({
        title: 'ناموفق',
        text: 'مشکلی پیش آمده لطفا با پشتیانی تماس بگیرید',
        icon: 'error',
        confirmButtonText: 'متوجه شدم'
      });
    }
  };

  useEffect(() => {
    if (Cookies.get('token')){
      navigate('/')
    }
  }, []);

  return (
    <section className="loginPage">
      <div className="loginImageContainer">
        <img src="/img/login2.png" className="loginImage"/>
      </div>
      <div className="LoginInfoSection">
        <div className="loginheader">
          {/* <img src="/img/logo.png"/> */}
          <h1 className="loginTextName">GALATA B2B WEBSITE </h1>
        </div>
        <div className="loginBoxSection">
          <label className="loginInfo" htmlFor="username">شماره تلفن</label>
          <input className="inputBox" id="username" onChange={(input: any) => setUsername(input.target.value)} />
          <label className="loginInfo marginTop" htmlFor="password">رمز ورود</label>
          <input className="inputBox" type="password" onChange={(input: any) => setPassword(input.target.value)} id="password"/>
          <a className="forgotPassword">رمز خود را فراموش کرده اید؟</a>
          <span className="loginButtom" onClick={send}>ورود</span>
        </div>
      </div>
    </section>
  );
};
export default Login;