import Header from "../../layouts/Header";
import { ReactElement, useEffect, useState } from "react";
import { setLoading } from "../../services/reducers/homeSlice";
import { useDispatch } from "react-redux";
import restApi from "../../services/restApi";
import { useNavigate } from "react-router-dom";

const Blog = () =>{
  const dispatch = useDispatch()
  const [data, setData] = useState<any>()
  const navigate = useNavigate();
  
  const list = () =>{
    const rows: ReactElement[] = [];
    
    data?.map((article, index)=>{
      rows.push(
        <div className="blogCard clickable" onClick={() => navigate('/blog/' + article.slug)}>
          <img className="blogCardImg" src={article.media?.url}/>
          <p className="cardTitle">
            {article.title}
          </p>
          <a className="cardLink">
            <p>مشاهده همه</p>
            <i></i>
          </a>
        </div>
      )}
    )
    
    return rows;
  }
  const fetchData = async () =>{
    dispatch(setLoading(true));
    
    const res = await restApi(process.env.REACT_APP_BASE_URL + '/article/' ).get();
    
    if(res.code == 200) {
      setData(res.data);
    }
    
    dispatch(setLoading(false));
  };
  
  useEffect( () =>{
    fetchData();
  },[])
  
  return(
    <>
    <Header />
      <div className="categoryBanner"></div>
      <main className="singleProductMain blogbackground">
        {/* <div className="mainHeader"> */}
        {/*   <div className="seaechBox keepRight"> */}
        {/*     <i className="magnifierIcon"></i> */}
        {/*     <input className="searchInput" placeholder="جستجو"></input> */}
        {/*   </div> */}
        {/*   <span className="titleTabs">جدیدترین</span> */}
        {/*   <span className="titleTabs active">پربازدیدترین</span> */}
        {/*   <h3 className="title">خواندنی ها</h3> */}
        {/* </div> */}
        <section className="blogContainer">
        {/*<div className="BlogcardContainer">*/}
          {list()}
        {/*</div>*/}
        </section>
      </main>
</>
  )
}
export default Blog;