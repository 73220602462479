import { Sidebar } from "../Layout/Sidebar";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setLoading } from "../../../services/reducers/homeSlice";
import restApi from "../../../services/restApi";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import attribute from "./index";

const AddAttribute = () => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [attributes, setAttributes] = useState([])
  const [parentId, setParentId] = useState()
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const [attributeGroups,setAttributeGroups]=useState([])
  const [selectedAttributeGroups,setSelectedAttributeGroups]=useState([])
  
  const send = async () => {
    dispatch(setLoading(true));
    
    const res = await restApi(process.env.REACT_APP_BASE_URL + '/admin/attribute', true).post({
      title: title,
      description: description,
      parentId: parentId,
      attributeGroups: selectedAttributeGroups
    })
    
    if(res.code == 200){
      Swal.fire({
        title: 'موفق',
        text: 'مشخصه با موفقیت ساخته شد',
        icon: 'success',
        confirmButtonText: 'متوجه شدم',
        didClose() {
          navigate('/dashboard/attribute')
        }
      })
    } else {
      Swal.fire({
        title: 'ناموفق',
        text: res?.data,
        icon: 'error',
        confirmButtonText: 'متوجه شدم'
      })
    }
    
    dispatch(setLoading(false));
  }
  
  const fetchData = async () => {
    dispatch(setLoading(true));
    
    const res = await restApi(process.env.REACT_APP_BASE_URL + '/admin/attribute', true).get();
    
    if(res.code == 200){
      setAttributes(res.data);
    }
    const result = await restApi(process.env.REACT_APP_BASE_URL + '/admin/attributeGroup', true).get();
    
    if(result.code == 200) {
      setAttributeGroups(result.data);
    }
    dispatch(setLoading(false));
  };
  
  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <body className="dashboardBody">
      <Sidebar />
      <main className="dashBoardMain main">
        <div className="addInfoHeader">
          <span className="dashboardHeader keepRight clickable" onClick={send}>
            ایجاد مشخصه
          </span>
          <span>
            <h1 className="sideBarTitle">بازگشت به صفحه مشخصات فنی</h1>
             <h1 className="dashBoardTitle">افزودن مشخصه جدید</h1>
          </span>
          <i className="backAdd clickable" onClick={() => navigate("/dashboard/attribute/")}></i>
        </div>
        <section className="bottomSection">
          <h1 className="dashBoardTitle">اطلاعات مشخصه</h1>
          <form className="addInfo">
            <label className="sideBarTitle" htmlFor="infoTitle">عنوان</label>
            <input className="addInput" id="infoTitle" onChange={(input: any) => setTitle(input.target.value)}/>
            <label className="sideBarTitle" >والد</label>
            <select className="addInput dashboardSelector" id="infoTitle" onChange={(input: any) => setParentId(input.target.value)}>
              <option value={null}>بدون والد</option>
              {attributes?.map((attribute: any) => <option value={attribute.id}>{attribute.title}</option>)}
            </select>
            <label className="sideBarTitle" htmlFor="infoTitle">دسته بندی</label>
            <select className="addInput dashboardSelector" id="infoTitle" multiple={true} onChange={(input: any) => setSelectedAttributeGroups(Array.from(input.target.selectedOptions, (option: any) => option?.value))}>
              {attributeGroups?.map((attributeGroup: any) => <option value={attributeGroup.id}>{attributeGroup.title}</option> )}
            </select>
            <label className="sideBarTitle" htmlFor="infoAmount">توضیحات</label>
            <textarea className="addInput" placeholder="توضیحات" id="infoAmount" onChange={(input: any) => setDescription(input.target.value)}/>
          </form>
        </section>
      </main>
      </body>
    </>
  )
}
export default AddAttribute;