import moment from 'jalali-moment';
import { ReactElement, useState } from 'react';
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import Header from '../../layouts/Header';
import PayDetail from '../../layouts/Modal/PayDetail';
import { popupSlice } from '../../services/reducers';
import { setLoading } from '../../services/reducers/homeSlice';
import restApi from '../../services/restApi';
import { useAppSelector } from '../../services/store';
import tools from '../../utils/tools';
import Favorites from './Favorites';
import OrderDetail from './OrderDetail';
import SideBar from './SideBar';

const UserDashboard = () => {
  const dispatch = useDispatch();
  const userReducer = useAppSelector(state => state.userReducer);
  const statuses = useAppSelector(state => state.homeReducer.statuses);
  const [detail, setDetail] = useState();
  const [tab, setTab] = useState('current');
  const tabTitles = {
    favorites: 'علاقه مندی ها',
    canceled: 'کنسل شده',
    returned: 'مرجوع شده',
    sent: 'ارسال شده',
    current: 'جاری'
  };

  const cancel = async (id: number) => {
    if (confirm('آیا مطمئن هستید؟')) {
      dispatch(setLoading(true));

      const res = await restApi(process.env.REACT_APP_BASE_URL + '/order/cancel/' + id, true).post({});

      if (res.code == 200) {
        Swal.fire({
          title: 'موفق',
          text: 'سفارش با موفقیت لفو شد',
          icon: 'success',
          confirmButtonText: 'متوجه شدم'
        });
      } else {
        Swal.fire({
          title: 'ناموفق',
          text: 'مشکلی پیش آمده لطفا دوباره تلاش کنید یا با پشتیبانی تماس بگیرید.',
          icon: 'error',
          confirmButtonText: 'متوجه شدم'
        });
      }

      dispatch(setLoading(false));
    }
  };

  const list = () => {
    if (tab == 'favorites'){
      return <Favorites />
    }
    const rows: ReactElement[] = [];

    userReducer?.orders?.filter((order: any) => {
      switch (tab) {
        case('canceled'):
          return order.status == 10;
        case('returned'):
          return order.status == 11;
        case('sent'):
          return order.status == 9;
        default:
          return order.status < 9;
      }
    }).sort((a: any, b: any)=> moment(b.createdAt).unix() - moment(a.createdAt).unix()).map((order: any, index) => {
      const status = statuses[order?.status];
      rows.push(
        <tr className="tr2" key={'order' + index} onClick={() => setDetail(order)}>
          <td className="trDate">{moment(order?.createdAt).format('jYYYY/jMM/jDD HH:MM')}</td>
          <td className="trOrderNumber">{order.code}</td>
          <td className="catPrice">
            <p className="tablePrice">DHs {tools.formatPrice(order?.price)}</p>
            <div className="persianPrice">
              <p className="tablePriceNumber">{tools.formatPrice(order.priceToman)}</p>
              <p className="tablePriceTexe">تومان</p>
            </div>
          </td>
          <td className="catPayStatus" style={{ color: status?.color }}>{status?.title}</td>
          {(status?.id == 3 || status?.id == 6) &&
              <td className="catPayButtons keepRight">
                  <span className="payButton clickable" onClick={() => dispatch(popupSlice.middle(<PayDetail order={order}/>))}>پرداخت</span>
                  <span className="cancelPayButton clickable" onClick={() => cancel(order.id)}> لغو سفارش</span>
              </td>
          }
          <i className="back2 svg keepRight"></i>
        </tr>
      );
    });

    return rows;
  };

  const content = () => {
    if (!detail) {
      return (
        <div className="dashboardTableContainer">
          <table className="userTable">
            <thead>
            <tr className="dashboardTr1">
              <th className="">وضعیت</th>
              <th className="priceTd">مبلغ</th>
              <th>شماره سفارش</th>
              <th className="dateTet">تاریخ</th>
            </tr>
            </thead>
            {list()}
          </table>
        </div>
      );
    } else {
      return (
        <OrderDetail order={detail} setOrder={setDetail}/>
      );
    }
  };

  return (
    <>
      <Header/>
      <main className="dashboardPage">
        <section className="dashboardLeftSection">
          <div className="dashboardTabs">
            {Object.entries(tabTitles).map(([key, value], index) => <span className={'tabsText clickable ' + (tab == key ? 'active' : '')} key={'tab' + index} onClick={() => {
              setTab(key);
              setDetail(undefined);
            }}>{value}</span>)}
          </div>
          {content()}
        </section>
        <SideBar/>
      </main>
    </>
  );
};
export default UserDashboard;