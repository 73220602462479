import React, { useState } from 'react';
import tools from '../../utils/tools';

const TooltipImage = ({ src }) => {
  const [showTooltip, setShowTooltip] = useState(false);
  return (
    <>
      {showTooltip && <span className="productImageTooltip" onMouseLeave={() => setShowTooltip(false)}>
          <img className="productImageTooltipImg" src={src}/>
        </span>}
      <img className="imgSingle" src={src} onMouseEnter={() => setShowTooltip(true)}/>
    </>
  );
};

export default TooltipImage;