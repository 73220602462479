import moment from 'jalali-moment';
import { ReactElement, useEffect, useState } from 'react';
import ReactPaginate from 'react-paginate';
import { useDispatch } from 'react-redux';
import { useNavigate, useSearchParams } from 'react-router-dom';
import Swal from 'sweetalert2';
import usePagination from '../../../hooks/usePagination';
import { popupSlice } from '../../../services/reducers';
import { setLoading } from '../../../services/reducers/homeSlice';
import restApi from '../../../services/restApi';
import tools from '../../../utils/tools';
import { Sidebar } from '../Layout/Sidebar';
import Derham from '../Modal/Derham';

const Product = () => {
  const [data, setData] = useState([]);
  const [brands, setBrands] = useState({});
  const [tab, setTab] = useState('all');
  const [productCount, setProductCount] = useState(0);
  const [query, setQuery] = useState('');
  const [searchParams, setSearchParams] = useSearchParams();
  const { pageCount, endOffset, itemOffset, itemsPerPage, setPageCount, setItemOffset, setItemsPerPage } = usePagination(data)
  let currentItems = data?.slice(itemOffset, endOffset);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const deleteItem = async (id: number) => {
    if(confirm('آیا مطمئن هستید؟')){
      dispatch(setLoading(true));

      const res = await restApi(process.env.REACT_APP_BASE_URL + '/admin/product/' + id, true).delete({});
      if(res.code == 204){
        Swal.fire({
          title: 'موفق',
          text: 'محصول با موفقیت حذف شد',
          icon: 'success',
          confirmButtonText: 'متوجه شدم'
        })
      } else {
        Swal.fire({
          title: 'ناموفق',
          text: res?.data,
          icon: 'error',
          confirmButtonText: 'متوجه شدم'
        })
      }
      dispatch(setLoading(false));
      await fetchData()
    }
  }

  const list = () => {
    const rows = [];

    currentItems.filter(e => query.length == 0 ? !e.isPre : true)?.map((product: any, index) => {
      rows.push(
        <tr className={`dashTr2${product.isPre ? ' bgPre' : ''}`}>
          <td className="">
            <a href={`/product/${product.slug}`}><i className="eye clickable"></i></a>
            <i className="trash clickable" onClick={() => deleteItem(product.id)}></i>
            <i className="edit clickable" onClick={() => navigate('/dashboard/product/edit/' + product.id)}></i>
          </td>
          <td>sort</td>
          <td className="">{product?.sku}</td>
          <td>{product?.length > 0 ? product?.products[0]?.price : '-'}</td>
          <td className="">
            <p>{moment(product?.updatedAt).format('jYYYY/jMM/jDD')}</p>
            <p>{moment(product?.updatedAt).format('HH:ss')}</p>
          </td>
          <td className="">{product?.title}</td>
          <td>
            <img src={tools.getMainPic(product?.medias)?.url} className="width100p"/>
          </td>
          <td>{++index}</td>
        </tr>
      )
    })

    return rows;
  };

  const tabs = () => {
    const rows: ReactElement[] = [];

    Object.entries({ all: { title: 'همه', count: Object.values(brands).reduce((acc, curr: any) => acc + curr.count, 0)}, ...brands}).map(([key, value]: [key: string, value: any]) => rows.push(
          <span className={`ordersTag ${key == tab ? 'activeTab' : ''}`} onClick={() => setTab(key)} key={key}>
            {value.title}
            <span className={`numberTag ${key ==  tab ? 'activeTab' : ''}`}>{value.count}</span>
          </span>
    ))

    return rows;
  };

  const fetchData = async () => {
    dispatch(setLoading(true));
    const params: any = {
      page: searchParams.get('page') || 1,
      perPage: itemsPerPage,
      query: query
    }
    if (tab != 'all'){
      params.brand = tab
    }
    const res = await Promise.all([

      await restApi(process.env.REACT_APP_BASE_URL + '/admin/product', true).get(params),
    ]);

    if(res[0].code == 200){
      setData(res[0].data.products);
      setProductCount(res[0].data.count)
      setPageCount(Math.floor(res[0].data.count / itemsPerPage));
      setBrands(res[0].data.brandCount)
    }

    dispatch(setLoading(false));
  };

  useEffect(() => {
    fetchData();
  }, [searchParams.get('page'), itemsPerPage, query, tab]);

  return(
    <>
      <body className="dashboardBody">
      <Sidebar />
      <main className="dashBoardMain">
        <h1 className="dashBoardTitle">لیست محصولات</h1>
        <div className="dashTabs">
          {tabs()}
        </div>
        <div className="searchContainer">
          <div className="keepRight svgContainer">
          <span className="dashboardHeader clickable" onClick={() => navigate('/dashboard/product/add')}>
            <p>افزودن محصول جدید</p>
            <i className="addPlus"></i>
          </span>
          <span className="dashboardHeader clickable" onClick={() => navigate('/dashboard/product/pre')}>
            <p>پیش محصول ها</p>
          </span>

          </div>
          <div className='itemsPerPage'>
            <label htmlFor="itemPerPage">تعداد در صفحه</label>
            <input id='itemPerPage' defaultValue={itemsPerPage} onChange={(input) => input.target.value && Number(input.target.value) > 0 && setItemsPerPage(Number(input.target.value))}/>
          </div>
          <div className="dashboardseaechBox">
            <i className="dashMagnifierIcon"></i>
            <input className="dashSearchInput" placeholder="جستجو" onChange={(input) => {
              setQuery(input.target.value);
              setSearchParams({['page']: '1'})
            }} />
          </div>
        </div>
      <table>
        <thead>
        <tr className="dashTr1">
          <th className="">عملیات</th>
          <th>چینش</th>
          <th className="">SKU</th>
          <th>قیمت</th>
          <th className="">
            <p> آخرین ویرایش</p>
            <i className="upDown"></i>
          </th>
          <th className="">عنوان</th>
          <th>عکس</th>
          <th>ردیف</th>
        </tr>
        </thead>
        <tbody>
        {list()}
        </tbody>
      </table>
        <ReactPaginate
          breakLabel="..."
          nextLabel="بعدی >"
          onPageChange={(event) => {
            setSearchParams({['page']: (Number(event.selected) + 1).toString()})
            setItemOffset((event.selected * itemsPerPage) % data.length);
          }}
          initialPage={searchParams.get('page') ? Number(searchParams.get('page')) - 1 : 0}
          pageRangeDisplayed={5}
          pageCount={pageCount}
          previousLabel="< قبلی"
          renderOnZeroPageCount={null}
          className="pagination"
          pageClassName="paginationBreak"
          previousClassName="paginationBreak"
          nextClassName="paginationBreak"
          activeClassName="paginationActive"
        />
      </main>
      </body>
    </>
  )
}
export default Product