import { Sidebar } from "../Layout/Sidebar";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setLoading } from "../../../services/reducers/homeSlice";
import restApi from "../../../services/restApi";
import Swal from "sweetalert2";
import { useNavigate, useParams } from "react-router-dom";

const EditDashBlog = () => {
  const [item, setItem] = useState<any>()
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate()
  const [long, setLong] = useState('')
  const [short, setShort] = useState('')
  const [keywords, setKeywords] = useState('')
  const [selectedAttributeGroups, setSelectedAttributeGroups] = useState([])
  const [articleCategories, setArticleCategories] = useState([])
  
  const send = async () => {
    dispatch(setLoading(true));
    
    const res = await restApi(process.env.REACT_APP_BASE_URL + '/admin/article/' + id, true).put({
      title: title,
      long: long,
      short: short,
      keywords: keywords,
      articleCategories: articleCategories,
    })
    
    if(res.code == 200) {
      Swal.fire({
        title: 'موفق',
        text: 'مقاله با موفقیت ویرایش شد',
        icon: 'success',
        confirmButtonText: 'متوجه شدم'
      })
    } else {
      Swal.fire({
        title: 'ناموفق',
        text: res?.data,
        icon: 'error',
        confirmButtonText: 'متوجه شدم'
      })
    }
    
    dispatch(setLoading(false));
  }
  const fetchData = async () => {
    dispatch(setLoading(true))
    
    const res = await restApi(process.env.REACT_APP_BASE_URL + '/admin/articleCategory', true).get()
    
    if(res.code == 200) {
      setArticleCategories(res.data);
    }
    dispatch(setLoading(false))
  }
    const fetchItem = async () => {
      dispatch(setLoading(true))
      
      const res = await restApi(process.env.REACT_APP_BASE_URL + '/admin/article/' + id, true).get()
      
      if(res.code == 200) {
        setItem(res.data)
      }
      
      dispatch(setLoading(false))
    }
    
    useEffect(() => {
      fetchItem()
    }, [])
    return (
      <>
        <body className="dashboardBody">
        <Sidebar/>
        <main className="dashBoardMain main">
          <div className="addInfoHeader">
          <span className="dashboardHeader keepRight clickable" onClick={send}>
           ارسال
          </span>
            <span>
            <h1 className="sideBarTitle">بازگشت به صفحه مقالات</h1>
             <h1 className="dashBoardTitle">ویرایش مقاله </h1>
          </span>
            <i className="backAdd clickable" onClick={() => navigate("/dashboard/dashblog/")}></i>
          
          </div>
          <section className="bottomSection">
            <h1 className="dashBoardTitle">اطلاعات مقاله</h1>
            <form className="addInfo">
              <label className="sideBarTitle" htmlFor="infoTitle">عنوان</label>
              <input className="addInput" id="infoTitle" onChange={(input: any) => setTitle(input.target.value)}
                     value={title || item?.title}/>
              <label className="sideBarTitle" htmlFor="infoTitle">دسته بندی</label>
              <select className="addInput dashboardSelector" id="infoTitle" multiple={true} onChange={(input: any) => setSelectedAttributeGroups(Array.from(input.target.selectedOptions, (option: any) => option?.value))}>
                {articleCategories?.map((attributeGroup: any) => <option value={attributeGroup.id}>{attributeGroup.title}</option>)}
              </select>
              <label className="sideBarTitle" htmlFor="infoTitle">کلیدواژه</label>
              <input className="addInput" id="infoTitle" onChange={(input: any) => setKeywords(input.target.value)} value={keywords || item?.keywords}/>
              <label className="sideBarTitle" htmlFor="infoAmount">توضیح کوتاه</label>
              <textarea className="addInput" placeholder="توضیحات" id="infoAmount"
                        onChange={(input: any) => setShort(input.target.value)} value={short || item?.short} />
              <label className="sideBarTitle" htmlFor="infoAmount">توضیح بلند</label>
              <textarea className="addInput" placeholder="توضیحات" id="infoAmount"
                        onChange={(input: any) => setLong(input.target.value)} value={long || item?.long}/>
            </form>
          </section>
        </main>
        </body>
      </>
    )
}
export default EditDashBlog;