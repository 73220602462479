import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Swal from 'sweetalert2';
import useTicker from '../../hooks/useTicker';
import { setLoading } from '../../services/reducers/homeSlice';
import { cart } from '../../services/reducers/userSlice';
import restApi from '../../services/restApi';
import tools from '../../utils/tools';

const CartRow = ({product}) => {
  const [count, setCount] = useState(product?.count);
  const dispatch: any = useDispatch();
  const { ticker, calcPrice } = useTicker();
  const send = async () => {
    dispatch(setLoading(true));

    const res = await restApi(process.env.REACT_APP_BASE_URL + '/cart/updateCount', true).put({
      productId: product.productId,
      orderId: product.orderId,
      newCount: count
    });
    dispatch(cart());

    if(res.code != 200){
      Swal.fire({
        title: 'ناموفق',
        text: res?.data,
        icon: 'error',
        confirmButtonText: 'متوجه شدم'
      })
    }

    dispatch(setLoading(false));
  };

  useEffect(() => {
    if (count !== product?.count) {
      send();
    }
  }, [count]);

  return (
    <div className="basketTr">
      <i className="basketCancel" onClick={() => setCount(0)}></i>
      <div className="tablePrice">
          <span className="tablePrice">
            DHs {count * Number(calcPrice(product?.product?.price, false))}
          </span>
        <span className="tablePersianPrice">
            <h5 className="tablePriceNumber">{tools.formatPrice(ticker( count * Number(calcPrice(product?.product?.price, false))))} </h5>
            <h6 className="tablePriceTexe"> تومان</h6>
          </span>
      </div>
      <span className="quantity">
         <div className="quantityButtom">
           <i className="tablePlusIcon" onClick={() => setCount(prevState => prevState + 1)}></i>
           <input className="quantityNumber" value={count} onBlur={(input: any) => setCount(input.target.value)}/>
           <i className="tableCollapsIcon" onClick={() => setCount(prevState => prevState - 1)}></i>
         </div>
      </span>
      <div className="basketInfo2">
        <span className="basketLenzModel">DHs {calcPrice(product?.product?.price)}</span>
      </div>
      <div className="basketInfoContainer">
        <h3 className="basketProductBrand">{product?.prodcut?.title}</h3>
        <h4 className="basketSerial">{product?.product?.sku}</h4>
        <h3 className="basketPrice"><a href={`/product/${product?.product?.slug}`} target="_blank">{product?.product?.title}</a></h3>
      </div>
      <img src={tools.getMainPic(product?.product?.medias)?.url} className="basketImg"/>
      <p>۱</p>
    </div>
  );
};

export default CartRow;
