import { Sidebar } from "../Layout/Sidebar";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { setLoading } from "../../../services/reducers/homeSlice";
import restApi from "../../../services/restApi";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

const AddTag = () => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const send = async () => {
    dispatch(setLoading(true));
   
    const res = await restApi(process.env.REACT_APP_BASE_URL + '/admin/tag', true).post({
      title: title,
      description: description
    })

    if(res.code == 200){
      Swal.fire({
        title: 'موفق',
        text: 'تگ با موفقیت ساخته شد',
        icon: 'success',
        confirmButtonText: 'متوجه شدم',
        didClose() {
          navigate('/dashboard/tag')
        }
      })
    } else {
      Swal.fire({
        title: 'ناموفق',
        text: res?.data,
        icon: 'error',
        confirmButtonText: 'متوجه شدم'
      })
    }
    
    dispatch(setLoading(false));
  }
  
  return (
    <>
      <body className="dashboardBody">
      <Sidebar />
      <main className="dashBoardMain main">
        <div className="addInfoHeader">
          <span className="dashboardHeader keepRight clickable" onClick={send}>
            ایجاد تگ
          </span>
          <span>
            <h1 className="sideBarTitle">بازگشت به صفحه تگ ها</h1>
             <h1 className="dashBoardTitle">افزودن تگ جدید</h1>
          </span>
          <i className="backAdd clickable" onClick={() => navigate("/dashboard/tag/")}></i>
        </div>
        <section className="bottomSection">
          <h1 className="dashBoardTitle">اطلاعات تگ</h1>
          <form className="addInfo">
            <label className="sideBarTitle" htmlFor="infoTitle">عنوان</label>
            <input className="addInput" id="infoTitle" onChange={(input: any) => setTitle(input.target.value)}/>
            <label className="sideBarTitle" htmlFor="infoAmount">توضیحات</label>
            <textarea className="addInput" placeholder="توضیحات" id="infoAmount" onChange={(input: any) => setDescription(input.target.value)}/>
          </form>
        </section>
      </main>
      </body>
    </>
  )
}
export default AddTag;