import Swal from 'sweetalert2';

const alertHandler = (res, successMessage, onClose = null) => {
  const errorMessage = Array.isArray(res) ? res.find(e => e && e?.code?.toString().at(0) != 2)?.data : (res.code?.toString().at(0) != 2 && res.data);
  if (!errorMessage) {
    Swal.fire({
      title: 'موفق',
      text: successMessage,
      icon: 'success',
      confirmButtonText: 'متوجه شدم',
      didClose() {
        onClose && onClose()
      }
    });
  } else {
    Swal.fire({
      title: 'ناموفق',
      text: errorMessage,
      icon: 'error',
      confirmButtonText: 'متوجه شدم'
    });
  }
}

export default alertHandler;
