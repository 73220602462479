import { Sidebar } from "../Layout/Sidebar";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { setLoading } from "../../../services/reducers/homeSlice";
import restApi from "../../../services/restApi";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";
import attribute from "../Attribute";
import { attributes } from "js-cookie";

const AddAttributeValue = () => {
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const [attributes,setAttributes]=useState([])
  const [attributeId, setAttributeId] = useState()

  const send = async () => {
    dispatch(setLoading(true));
    
    const res = await restApi(process.env.REACT_APP_BASE_URL + '/admin/attributeValue', true).post({
      title: title,
      description: description,
      attributeId: attributeId,
    })
    
    if(res.code == 200){
      Swal.fire({
        title: 'موفق',
        text: 'مقدار مشخصات فنی با موفقیت ساخته شد',
        icon: 'success',
        confirmButtonText: 'متوجه شدم',
        didClose() {
          navigate('/dashboard/attributeValue')
        }
      })
    } else {
      Swal.fire({
        title: 'ناموفق',
        text: res?.data,
        icon: 'error',
        confirmButtonText: 'متوجه شدم'
      })
    }
    
    dispatch(setLoading(false));
  }
  const fetchData = async () => {
    dispatch(setLoading(true))
    
   
    const result = await restApi(process.env.REACT_APP_BASE_URL + '/admin/attribute/', true).get()
    if(result.code== 200){
      setAttributes(result.data)
      setAttributeId(result.data[0]?.id)
    }
    dispatch(setLoading(false))
  }
  
  useEffect(() => {
    fetchData()
  }, [])

  return (
    <>
      <body className="dashboardBody">
      <Sidebar />
      <main className="dashBoardMain main">
        <div className="addInfoHeader">
          <span className="dashboardHeader keepRight clickable" onClick={send}>ایجاد مقدار </span>
          <span>
            <h1 className="sideBarTitle">بازگشت به صفحه مقادیر مشخصات فنی</h1>
             <h1 className="dashBoardTitle">افزودن مقدار مشخصات فنی جدید</h1>
          </span>
          <i className="backAdd clickable" onClick={() => navigate("/dashboard/attributeValue/")}></i>
        </div>
        <section className="bottomSection">
          <h1 className="dashBoardTitle"> اطلاعات مقادیر مشخصات فنی</h1>
          <form className="addInfo">
            <label className="sideBarTitle" htmlFor="infoTitle">عنوان</label>
            <input className="addInput" id="infoTitle" onChange={(input: any) => setTitle(input.target.value)}/>
            <label className="sideBarTitle" >مشخصه فنی مربوطه</label>
            <select className="selector" id="infoTitle" onChange={(input: any) => setAttributeId(input.target.value)}>
              {attributes?.map((attribute: any) => <option value={attribute.id}>{attribute.title}</option>)}
            </select>
            <label className="sideBarTitle" htmlFor="infoAmount">توضیحات</label>
            <textarea className="addInput" placeholder="توضیحات" id="infoAmount" onChange={(input: any) => setDescription(input.target.value)}/>
          </form>
        </section>
      </main>
      </body>
    </>
  )
}
export default AddAttributeValue;