import React, { ReactElement, useEffect, useState } from "react";
import { setLoading } from "../../services/reducers/homeSlice";
import { useDispatch } from "react-redux";
import restApi from "../../services/restApi";
import { useNavigate, useParams } from "react-router-dom";
import { ProductRow } from "../SingleProduct/ProductRow";
import Header from "../../layouts/Header";

const SingleArticle = () => {
  const { slug } = useParams();
  const [data, setData] = useState<any>()
  const dispatch: any = useDispatch();
  const navigate = useNavigate();
  
  const fetchItem = async () => {
    dispatch(setLoading(true));
    
    const res = await restApi(process.env.REACT_APP_BASE_URL + '/article/' + slug).get();
    
    if(res.code == 200) {
      setData(res.data);
    }
    
    dispatch(setLoading(false));
  };
    
    useEffect(() => {
      fetchItem();
    }, []);
  
  console.log(data)
    
    return (
      <>
        <Header />
        <section className="blogPage">
          <div className="blogHeader">
            <i></i>
            <p className="BlogTitle">
              {data?.title}
            </p>
            <span className="clickable backSpan" onClick={() => navigate('/blog')}>
              <h6 className="blogExit" >بازگشت </h6>
              <img className="blogExitIcon"/>
            </span>
          </div>
          <img src={data?.media?.url}/>
          <p className="blogP1">
            {data?.short}
          </p>
          <div>
            {/* <h1 className="blogTitle">The Process of Glasses Fogging</h1> */}
            <p className="blogP1">
              {data?.long}
            </p>
            <section className="blog2">
              <div className="blogP2">
                {/* <h6 className="blogTitle">Tape as a Solution</h6> */}
                
              </div>
            </section>
          </div>
        </section>
      </>
    )
}
export default SingleArticle;