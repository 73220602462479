import { ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';
import useTicker from '../../hooks/useTicker';
import tools from '../../utils/tools';

const SearchModal = ({ result }) => {
  const navigate = useNavigate();
  const { calcPrice } = useTicker();
  const products = () => {
    const rows: ReactElement[] = [];

    result.products?.slice(0, 4).map((product, index) => rows.push(
        <a href={`/product/${product.slug}`} className="searchCard" key={'resProduct' + index}>
          <div className="searchProduct width60p">
            <h6 className="searchProductTitle">{product?.title?.length > 35 ? product?.title.substring(0, 35) + '...' : product?.title}</h6>
            <span className="codeTag">{product?.sku}</span>
            <span className="searchProduct">
            DHs {calcPrice(product?.price)}
          </span>
            <span className="tablePersianPrice">
            <h5 className="tablePriceNumber">{calcPrice(product?.priceToman)} </h5>
            <h6 className="tablePriceTexe"> تومان</h6>
          </span>
          </div>
          <img src={tools.getMainPic(product?.medias)?.url} className="searchCardImg"/>
        </a>
    ));

    return rows;
  };

  const list = (list: any[], type: string) => {
    const rows: ReactElement[] = [];

    list.slice(0, 10).map((item, index) => rows.push(
      <span onClick={() => navigate(`/${type}/${item.slug}`)} className="codeTag tag">{item.title}</span>
    ));

    return rows;
  };

  return (
    <div className={'searchBox flex'}>
      {result.products?.length > 0 &&
          <section className="searchCardSection">
            {products()}
          </section>
      }
      {(result.brands.length > 0 || result.categories.length > 0 || result.tags.length > 0) &&
          <section className="rightSearch">
            {(result.brands.length > 0 || result.categories.length > 0) &&
                <div className="tagSection">
                  {result.brands.length > 0 &&
                      <div className="tagContainer">
                          <h6 className="font16">برند</h6>
                          <div className="tagHolder">
                            {list(result.brands, 'brand')}
                          </div>
                      </div>
                  }
                  {result.categories.length > 0 &&
                      <div className="tagContainer">
                          <h6 className="font16">دسته بندی ها</h6>
                          <div className="tagHolder">
                            {list(result.categories, 'category')}
                          </div>
                      </div>
                  }
                </div>
            }
            {result.tags.length > 0 &&
                <div className="tagSection">
                  {/* <div className="tagContainer"> */}
                  {/*   <h6 className="font16">جستجوهای اخیر</h6> */}
                  {/*   <div className="tagHolder"> */}
                  {/*       <span className="codeTag tag"> */}
                  {/*         پرفورمنس */}
                  {/*         <i className="cancelTag"></i> */}
                  {/*       </span> */}
                  {/*     <span className="codeTag tag"> */}
                  {/*         اسپورت */}
                  {/*          <i className="cancelTag"></i> */}
                  {/*       </span> */}
                  {/*     <span className="codeTag tag"> */}
                  {/*         پروتکتیو */}
                  {/*          <i className="cancelTag"></i> */}
                  {/*       </span> */}
                  {/*     <span className="codeTag tag"> */}
                  {/*         لایف استایل */}
                  {/*          <i className="cancelTag"></i> */}
                  {/*       </span> */}
                  {/*   </div> */}
                  {/* </div> */}
                    <div className="tagContainer">
                        <h6 className="font16">تگ</h6>
                        <div className="tagHolder">
                          {list(result.tags, 'tag')}
                        </div>
                    </div>
                </div>
            }
          </section>
      }
    </div>
  );
};

export default SearchModal;
