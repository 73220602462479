import { Sidebar } from "../Layout/Sidebar";
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setLoading } from '../../../services/reducers/homeSlice';
import restApi from '../../../services/restApi';

import { useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import attributegroup from "../../Category";

const AttributeValue = () => {
  const [data, setData] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate()
  const [query, setQuery] = useState('');

  const deleteItem = async (id: number) => {
    if(confirm('آیا مطمئن هستید؟')){
      dispatch(setLoading(true));
      
      const res = await restApi(process.env.REACT_APP_BASE_URL + '/admin/attributeValue/' + id, true).delete({});
      
      if(res.code == 200){
        Swal.fire({
          title: 'موفق',
          text: ' مقدار مشخصات فنی با موفقیت حذف شد',
          icon: 'success',
          confirmButtonText: 'متوجه شدم'
        })
      } else {
        Swal.fire({
          title: 'ناموفق',
          text: res?.data,
          icon: 'error',
          confirmButtonText: 'متوجه شدم'
        })
      }
      
      dispatch(setLoading(false));
    }
  }
  const list = () => {
    const rows = [];

    data.filter((attributegroup:any) => attributegroup.title.concat(attributegroup.attribute.title).toLowerCase().includes(query.toLowerCase())).map((attributegroup: any, index) => {
      rows.push(
        <tr className="">
          <td className="svgContainer">
            <i className="eye"></i>
            <i className="trash clickable" onClick={() => deleteItem(attributegroup.id) }></i>
            <i className="edit clickable" onClick={() => navigate("/dashboard/attributeValue/edit/" + attributegroup.id)}></i>
          </td>
          <td className="">{attributegroup.createdAt}</td>
          <td className="">{attributegroup.description}</td>
          <td className="">{attributegroup?.attribute?.title}</td>
          <td className="">{attributegroup.title}</td>
          <td>{++index}</td>
        </tr>
      )
    })
    
    return rows;
  };
  
  const fetchData = async () => {
    dispatch(setLoading(true));
    
    const res = await restApi(process.env.REACT_APP_BASE_URL + '/admin/attributeValue', true).get();
    
    if(res.code == 200){
      setData(res.data);
    }
    
    dispatch(setLoading(false));
  };
  
  useEffect(() => {
    fetchData();
  }, []);
  
  return(
    
    <>
      <body className="dashboardBody">
      <Sidebar />
      <main className="dashBoardMain">
        <h1 className="dashBoardTitle">لیست مقادیر مشخصات فنی</h1>
        <div className="searchContainer">
          <span className="dashboardHeader clickable"  onClick={() => navigate('/dashboard/attributeValue/add')}>
            <p>افزودن مقدار مشخصات فنی جدید</p>
            <i className="addPlus"></i>
          </span>
          <div className="dashboardseaechBox">
            <i className="dashMagnifierIcon"></i>
            <input className="dashSearchInput" placeholder="جستجو" onChange={(input:any) => setQuery(input.target.value)}></input>
          </div>
        </div>
        <table className="listTable">
          <thead>
          <tr className="firstTR">
            <th>عملیات</th>
            <th>تاریخ ساخت</th>
            <th>توضیحات</th>
            <th>مشخصه فنی</th>
            <th>عنوان</th>
            <th>ردیف</th>
          </tr>
          </thead>
          <tbody>
          {list()}
          </tbody>
        </table>
      </main>
      </body>
    </>
  )
}
export default AttributeValue;