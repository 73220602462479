import Cookies from 'js-cookie';
import { ReactElement, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import 'swiper/css';
import "swiper/css/navigation";
import "swiper/css/pagination";
import SwiperCore from 'swiper'
import { Autoplay, Navigation, Pagination  } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';
import Header from '../../layouts/Header';
import { setLoading } from '../../services/reducers/homeSlice';
import restApi from '../../services/restApi';
const Home = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [tags, setTags] = useState([]);
  const [articles, setArticles] = useState([]);
  const [banners, setBanners] = useState([]);

  const sliderList = () => {
    const rows: ReactElement[] = [];

    banners.filter(e => e.code.includes('homeSlider')).map((banner) => rows.push(
      <SwiperSlide key={banner.code} onClick={() => window.location.href = banner.link}>
        <img className="bannerContainer" src={banner.media?.url} alt={banner.alt || banner.title}/>
      </SwiperSlide>
    ))

    return rows;
  }

  const tagList = () => {
    const rows: ReactElement[] = [];

    banners.filter(e => e.code.includes('homeCard')).map((card, index) => {
      rows.push(
        <a href={card.link}><article className="productCard" key={'tag' + index}>
          <div className="productCardInfo">
            <p className="cardTitle">{card.title}</p>
            <p className="cardsubtitle">{card.alt}</p>
            <i className="glassesIcon"></i>
            <span className="cardHeaderTag">
                <p>{card.description}</p>
              </span>
            <a className="cardLink">
              <p>مشاهده همه</p>
              <i></i>
            </a>
          </div>
          <img className="productCardImg" src={card.media?.url} alt={card.alt}/>
        </article>
        </a>
      )
    })

    return rows;
  };

  const articleList = () => {
    const rows: ReactElement[] = [];

    articles.slice(articles.length - 3, articles.length).map((article, index) => {
      rows.push(
        <a href={'/blog/' + article.slug} className="linkNone">
          <article className="magCard" key={'article' + index}>
            <img src={article.media?.url} alt={article.title} className="homeBlog"/>
            <p className="magCardTag">{article.categories[0]?.title}</p>
            <p className="magCardTitle">{article.title}</p>
            <p className="magcardText">{article.short.substring(0, 200)}</p>
          </article>
        </a>
      )
    })

    return rows;
  };

  const fetchData = async () => {
    dispatch(setLoading(true));

    const res = await Promise.all([
      await restApi(process.env.REACT_APP_BASE_URL + '/tag', true).get(),
      await restApi(process.env.REACT_APP_BASE_URL + '/article', true).get(),
      await restApi(process.env.REACT_APP_BASE_URL + '/banner', true).get(),
    ]);

    if (res[0]?.code == 200){
      setTags(res[0].data)
    }

    if (res[1]?.code == 200){
      setArticles(res[1].data)
    }

    if (res[2]?.code == 200){
      setBanners(res[2].data)
    }

    dispatch(setLoading(false));
  };

  useEffect(() => {
    if (!Cookies.get('token')){
      navigate('/login');
    }

    fetchData();
  }, []);

  return (
    <>
      <Header />
      <main className="homeMain">
        <Swiper
          modules={[Navigation, Pagination, Autoplay]}
          style={{width: '100%'}}
          direction="horizontal"
          slidesPerView={1}
          loop={true}
          navigation={true}
          autoplay={{ delay: 5000 }}
          pagination={true}
        >
          {sliderList()}
        </Swiper>
        <section className="cardSlider">
          {tagList()}
        </section>
        <section className="blogPageHome">
          <div className="magazineText">
            <a href="/blog"><h3 className="readMore">مشاهده همه</h3></a>
            <h1 className="magTitle">خواندنی ‌ها</h1>
          </div>
          <div className="magCardContainer">
            {articleList()}
          </div>
        </section>
        <footer>
          کلیه حقوق متعلق به شرکت گالاتا می باشد
        </footer>
      </main>
    </>
  )
}

export default Home;